const projectInfo = [
  // {
  //   id: 1,
  //   name: "SpicyNoods",
  //   description:
  //     "A fictional e-commerce site for shopping freshly hand pulled spicy noodles. Built with React, Redux, Express, Sequelize and PostgreSQL.",
  //   imageURL: "./images/spicynood.jpg",
  //   altText: "SpicyNoods screenshot",
  //   orientation: "portrait",
  //   github: "https://github.com/Spicy-Noods/Grace-Shopper",
  //   demo: "https://spicynood.herokuapp.com/",
  //   presentation: null,
  // },
  // {
  //   id: 2,
  //   name: "Spaceyan",
  //   description:
  //     "A classic 2D side scrollering space shooter built with Phaser.js, ES6 and webpack. Shoot those spaceships! Created as part of an independent Stackathon project at Fullstack Academy.",
  //   imageURL: "./images/space.png",
  //   altText: "shooting spaceships.",
  //   orientation: "landscape",
  //   github: "https://github.com/YanGao85/spaceyan3",
  //   demo: "https://spaceyan3.herokuapp.com/",
  //   presentation: null,
  // },
  {
    id: 1,
    name: 'Whats4Dinner',
    description:
      'A voice interactive recipe generator & instruction provider that helps users discover new recipes and assist in the kitchen. It can receive voice commands from users and perform actions based on your given commands. Built by a team of four developers, using React and Firebase.',
    imageURL:
      'https://camo.githubusercontent.com/3ac8d735e05dc86911ea3fb9a639cba3a497ec48/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f53714858446f32486f6933784733586d64342f67697068792e676966',
    altText: 'Recipe Generator',
    orientation: 'landscape',
    github: 'https://github.com/6Gawd/WhatsForDinner3',
    demo: 'https://whats4dinner.web.app/',
    presentation: 'https://youtu.be/QWUM5zslF6c',
  },
  // {
  //   id: 4,
  //   name: "Weather App",
  //   description:
  //     "A web app that displays local weather information and different pictures of dogs depending on the forecast. Built using jQuery as part of a freeCodeCamp project.",
  //   imageURL: "./images/doggyWeather.png",
  //   altText: "A doggy weather app",
  //   orientation: "landscape",
  //   github: "https://github.com/stella-yc/weather-app",
  //   demo: "https://stella-yc.github.io/weather-app/",
  //   presentation: null,
  // },
  // {
  //   id: 5,
  //   name: "Conway's Game of Life",
  //   description:
  //     "A web app that allows users to set up the initial state of the game by clicking on tiles, or generate a random start state. Built using pure JavaScript.",
  //   imageURL: "./images/gameOfLife.gif",
  //   altText: "Game of Life in action",
  //   orientation: "landscape",
  //   github: "https://github.com/stella-yc/conways-game-of-life",
  //   demo: "https://stella-yc.github.io/conways-game-of-life/",
  // },
];

export default projectInfo;
