import React from "react";
import "./App.css";
import Skills from "./Skills";
import Projects from "./Projects";
import About from "./About";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Header from "./Header";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Header />
      <Skills />
      <Projects />
      {/* <OtherProjects /> */}
      <About />
      <Footer />
    </div>
  );
}

export default App;
